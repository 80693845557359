import type { PropsWithChildren } from "react";

import { useClerk } from "@clerk/remix";
import { Avatar, AvatarFallback, AvatarImage, Popover, PopoverContent, PopoverTrigger } from "@infinitenz/pyrite";
import { Link, useNavigate } from "@remix-run/react";
import pkg from "react-microsoft-clarity";

import { useRootLoaderData, useUserLoaderData } from "~/modules/root/hook/useRootLoaderData";

const { clarity } = pkg;

export const UserProfilePopup = () => {
  const { signOut } = useClerk();
  const navigate = useNavigate();

  const { infinityOrganisationId } = useRootLoaderData();
  const { user } = useUserLoaderData();
  if (typeof window !== "undefined") {
    if (clarity.hasStarted()) {
      clarity.setTag("name", `${user.firstName} ${user.lastName}`);
      clarity.setTag("company", `${user.organisationName}`);
    }
  }

  return (
    <Popover>
      <PopoverTrigger className="h-full max-lg:hidden">
        <div className="flex h-full w-full max-w-96 flex-row items-center justify-start gap-2 border-l border-gray-200  pl-3 pr-2  text-start hover:text-blue-700">
          {user && (
            <>
              <Avatar>
                <AvatarImage />
                <AvatarFallback>{`${user.firstName[0] ?? ""}${user.lastName[0] ?? ""}`}</AvatarFallback>
              </Avatar>
              <div data-org={user.organisationId} data-user={user.userId}>
                <p className="line-clamp-1 text-left font-medium text-blue-600 " data-clarity-mask="True">
                  {user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : "No Name Found"}
                </p>
                <p className="line-clamp-1 overflow-ellipsis ">{user.organisationName}</p>
              </div>
              <div className="ri-arrow-down-s-line ri-xl text-blue-600" />
            </>
          )}
        </div>
      </PopoverTrigger>

      <PopoverContent className={"p-0"}>
        <UserProfileMenuOption to={"/organisation/selection"}>
          <div className="ri-swap-box-line ri-xl" />
          Switch Organisation
        </UserProfileMenuOption>

        <UserProfileMenuOption to={"/organisation/management/organisation"}>
          <div className="ri-building-line ri-xl" />
          Organisation Settings
        </UserProfileMenuOption>
        <UserProfileMenuOption to={"https://infinitystudiohelp.zendesk.com/hc/en-us/requests/new"} newTab={true}>
          <div className="ri-mail-send-line ri-xl" />
          Request Help
        </UserProfileMenuOption>
        <UserProfileMenuOption
          to={"https://infinitystudiohelp.zendesk.com/hc/en-us/categories/7456343240591-Apeiron"}
          newTab={true}
        >
          <div className="ri-question-line ri-xl" />
          Support Documentation
        </UserProfileMenuOption>

        {user.organisationId === infinityOrganisationId && (
          <UserProfileMenuOption to={"/infinity-guardian"}>
            <div className="ri-error-warning-line ri-xl" />
            Admin Tools
          </UserProfileMenuOption>
        )}

        <button
          onClick={() =>
            signOut(() =>
              navigate("/login", {
                replace: true,
              }),
            )
          }
          className={"flex w-full cursor-pointer items-center gap-x-2 border-b p-4 text-blue-600 hover:bg-primary-50"}
        >
          <i className="ri-logout-box-r-line ri-xl" />
          Sign out
        </button>
      </PopoverContent>
    </Popover>
  );
};

const UserProfileMenuOption = ({
  children,
  to,
  newTab,
}: PropsWithChildren<{
  to: string;
  newTab?: boolean;
}>) => {
  return (
    <Link
      viewTransition
      to={to}
      onClick={() => {
        document.dispatchEvent(new KeyboardEvent("keydown", { key: "Escape" }));
      }}
      target={newTab ? "_blank" : ""}
    >
      <div className={"flex items-center gap-x-2 border-b p-4 text-blue-600 hover:bg-primary-50"}>{children}</div>
    </Link>
  );
};
