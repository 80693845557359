import type { UIMatch } from "@remix-run/react";
import { Link, useMatches, useRouteLoaderData } from "@remix-run/react";
import type { PropsWithChildren, ReactNode } from "react";
import { Fragment } from "react";

import { DrawerNav } from "~/modules/layout/DrawerNav";
import { UserProfilePopup } from "~/modules/layout/UserProfilePopup";
import type { RouteHandlerProps } from "~/modules/root/type/handle";
import type { loader } from "~/root";

export const TOPNAV_HEIGHT = "4rem";

interface BaseNavProps extends PropsWithChildren {
  drawerSlot?: ReactNode;
  breadCrumbs?: ReactNode;
  logoURL?: string;
}

export const BaseNav = ({ breadCrumbs, drawerSlot, children, logoURL = "/logo.svg" }: BaseNavProps) => {
  return (
    <div
      className={`h-[${TOPNAV_HEIGHT}] sticky top-0 z-10 flex min-h-[4rem] w-full items-center justify-between border-b bg-white`}
    >
      <div className={"flex h-full items-center"}>
        {drawerSlot}
        {/* Don't remove reloadDocument this is needed to prevent SPA navigation while users are not signed in*/}
        <Link to="/" reloadDocument viewTransition>
          <img src={logoURL} className="ml-2 mr-1 max-h-10 min-w-20" alt={"Apeiron"} width={130} height={40} />
        </Link>
        {breadCrumbs}
      </div>
      {children}
    </div>
  );
};

export const Breadcrumb = () => {
  const matches = useMatches() as UIMatch<unknown, RouteHandlerProps>[];

  return (
    <span className=" bg-white pr-2 text-sm text-gray-500 max-sm:hidden  ">
      {matches
        .filter((match) => match.handle?.breadcrumb)
        .map((match, index, matchesWithBreadcrumbs) => {
          const isLast = index === matchesWithBreadcrumbs.length - 1;
          return (
            <Fragment key={match.id}>
              <span className={isLast ? "" : "text-blue-600"}>
                {match.handle.breadcrumb?.({
                  data: match.data,
                  isLast,
                  params: match.params,
                })}
              </span>
              {isLast ? null : <> / </>}
            </Fragment>
          );
        })}
    </span>
  );
};

export const TopNav = () => {
  const data = useRouteLoaderData<typeof loader>("root");

  return (
    <BaseNav breadCrumbs={<Breadcrumb />} drawerSlot={<DrawerNav />} logoURL={data?.logoURL}>
      <UserProfilePopup />
    </BaseNav>
  );
};
